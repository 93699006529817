import Vue from "vue";

export function getQueryUserInfo() {
    console.log("获取地址栏userid和phone")
    // 获取地址栏参数
    const url = window.location.href;
    const params = url.split('?')[1];
    if (!params) {
        return null;
    }
    const info = {};
    params.split('&').forEach(item => {
        const [key, value] = item.split('=');
        info[key] = value;
    });
    if(info.user_id) {
        Vue.prototype.$ls.set("user_id", info.user_id);
    }
    if(info.user_phone) {
        Vue.prototype.$ls.set("user_phone", decodeURIComponent(info.user_phone));
    }
    // let newUrl = url.split('?')[0];
    // if(newUrl !== window.location.href) {
    //     window.location.href = newUrl;
    // }
}