import Vue from 'vue'
import App from './App.vue'
import router from './router'
import service from './util/network'
import 'tailwindcss/tailwind.css'
Vue.config.productionTip = false
// 引入vant start
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)
// 引入vant end
// localstorage  start
import Storage from 'vue-ls'
import {getQueryUserInfo} from "@/util/getQueryUserInfo";

let options = {
	namespace: 'shangdian_', // key prefix
	name: 'ls', // name variable Vue.[ls] or this.[$ls],
	storage: 'local', // storage name session, local, memory
}

Vue.use(Storage, options)
// localstorage  end

getQueryUserInfo();

// axios
Vue.prototype.$axios = service
let userInfo = {}
export const vue = new Vue({
	router,
	// userInfo,
	render: (h) => h(App),
}).$mount('#app')
