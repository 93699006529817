<template>
  <div class="px-1 pt-10">
    <van-cell-group>
      <van-field v-model="phone" label="手机号" placeholder="请输入手机号" type="number" />
      <van-field
        v-model="sms"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
        type="digit"
      >
        <template #button>
          <div class="">
            <div v-show="showTime" class="flex flex-col justify-center items-center h-8">
              <van-count-down
                ref="countDown"
                :time="time"
                format="ss"
                millisecond
                @finish="finish"
              />
            </div>
            <van-button v-show="!showTime" @click="sendSms" size="small" type="default"
              >发送验证码</van-button
            >
          </div>
        </template>
      </van-field>
    </van-cell-group>
    <div class="flex items-center justify-center mt-5">
      <van-button @click="loginClick" color="#f35a5c" type="danger" size="large" round
        >立即登录</van-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      phone: "",
      sms: "",
      time: "60000",
      showTime: false,
      teacher_id: new URLSearchParams(window.location.search).get("teacher_id") || 0,
      custom_product: "",
    };
  },
  mounted() {},
  methods: {
    async sendSms() {
      let phone = this.phone;
      let smsRes = await this.$axios.post("/item/sendItemSms", {
        phone,
        area: "+86",
      });
      if (smsRes.code == 10001 || smsRes.code == 10000) {
        this.reset();
        this.showTime = true;
        this.$toast(smsRes.msg);
      } else {
        this.$toast.fail(smsRes.msg);
      }
    },
    finish() {
      this.showTime = false;
    },
    reset() {
      this.$refs.countDown.reset();
    },
    async loginClick() {
      if (!this.phone || !this.sms) {
        this.$toast.fail("请输入手机号或验证码");
      }
      let codeRes = await this.$axios.post("/item/checkItemSms", {
        phone: this.phone,
        code: this.sms,
      });
      if (codeRes.code == 10001 || codeRes.code == 10000) {
        let data = codeRes.data;
        this.$ls.set("user_id", data.user_id);
        this.$ls.set("user_phone", this.phone);
        this.$toast(codeRes.msg);
        if (this.teacher_id) {
          window.location.href = `/?teacher_id=${this.$route.query.teacher_id}`;
        } else {
          window.location.href = "/";
        }
      } else {
        this.$toast.fail(codeRes.msg);
      }
      // this.$root.userInfo = {
      // 	phone,
      // 	code,
      // }
    },
  },
};
</script>
<style>
.van-count-down {
  color: #323233;
  font-size: 1rem !important;
  line-height: 0.2rem;
}
</style>
