<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      user_id: new URLSearchParams(window.location.search).get("user_id") || 0,
      user_phone: new URLSearchParams(window.location.search).get("user_phone") || 0,
    };
  },

  mounted() {
    if (this.user_id != 0 && this.user_phone != 0) {
      this.$ls.set("user_id", this.user_id);
      this.$ls.set("user_phone", this.user_phone);
    }
  },
};
</script>
