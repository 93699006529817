<template>
  <div></div>
</template>
<script>
export default {
  name: "wxlogin",
  data() {
    return {
      appid: "wx555c6690aef40c46",
      code: "",
      state: "qweewq123",
      teacher_id: new URLSearchParams(window.location.search).get("teacher_id") || 0,
    };
  },
  mounted() {
    let code = this.$route.query.code;
    if (code) {
      this.getopenId(code);
    } else {
      let redirect_uri = encodeURIComponent(
        "https://mall.zhangcaijing.net/wxlogin?teacher_id=" + this.teacher_id
      );
      let url =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appid +
        "&redirect_uri=" +
        redirect_uri +
        "&response_type=code&scope=snsapi_base&state=" +
        this.state +
        "#wechat_redirect";
      window.location.href = url;
    }
  },
  methods: {
    async getopenId(code) {
      let res = await this.$axios.get("/wx/getWxOpenId", {
        params: {
          code,
          mp_name: "wcsj",
        },
      });
      if (res.code == 10001) {
        let test = {
          code: 10001,
          tip: "#2166",
          msg: "\u5fae\u4fe1\u767b\u5f55\u6210\u529f",
          data: { open_id: "oFZQy02WjqjDKPbSdv-Sp4wWpu74" },
        };
        this.$toast.success(res.msg);
        this.$ls.set("user_openid", res.data.open_id);
        setTimeout(() => {
          window.location.href = "/?teacher_id=" + this.teacher_id;
        }, 500);
      } else {
        this.$toast.fail(res.msg);
      }
    },
  },
};
</script>
<style></style>
