<template>
  <div id="app" class="bg-white-100 relative">
    <div class="w-full h-full md:w-1/2 mx-auto bg-white relative">
      <van-nav-bar
          :title="$route.meta.title ? $route.meta.title : '小商店'"
          :left-text="$route.meta.hiddenBack ? '' : '返回'"
          :right-text="$route.meta.hiddenBack ? '' : user_phone"
          @click-right="onClickRight"
          @click-left="onClickLeft"
      />
      <router-view class="pb-12"/>
      <van-tabbar v-model="active" active-color="#ee0a24" inactive-color="#000" v-show="!$route.meta.hiddenTabbar">
        <van-tabbar-item :to="'/?teacher_id=' + teacher_id" name="home" icon="home-o"
        >商品列表
        </van-tabbar-item
        >
        <van-tabbar-item :to="'/user?teacher_id=' + teacher_id" name="user" icon="manager-o"
        >我的订单
        </van-tabbar-item
        >
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import {Dialog} from "vant";
let that = null;
export default {
  name: "apppp",
  data() {
    return {
      active: "",
      user_phone: "",
      teacher_id: new URLSearchParams(window.location.search).get("teacher_id") || 0,
    };
  },
  mounted() {
    that = this;
    this.getRouteName();
    // this.checkLogin()
    let user_phone = this.$ls.get("user_phone");
    if (user_phone) {
      this.user_phone = user_phone.substr(0, 3) + "****" + user_phone.substr(7);
    }
  },
  methods: {
    checkLogin() {
      let user_id = this.$ls.get("user_id");
      console.log(this.$route.path);
      if (!user_id) {
        this.$router.push({
          name: "login",
          query: {
            teacher_id: this.teacher_id,
          },
        });
      }
    },
    getRouteName() {
      let routeName = this.$route.name;

      this.active = routeName;
      if (
          routeName != "login" &&
          routeName != "home" &&
          routeName != "wxlogin" &&
          routeName != "test"
      ) {
        // this.checkLogin();
      }
    },
    onClickRight() {
      if(this.$route.name === "custom-product") return;
      Dialog.confirm({
        title: "退出登录",
        message: "点击确认退出登录",
      })
          .then(() => {
            // on confirm
            this.$ls.remove("user_id");
            this.$ls.remove("user_phone");
            this.$ls.remove("user_openid");
            this.$ls.remove("address");

            if (this.teacher_id) {
              window.location.href = `/login?teacher_id=${this.teacher_id}`;
            } else {
              window.location.href = "/login"
            }
            // if (this.teacher_id) {
            //   window.location.href = `/?teacher_id=${this.teacher_id}`;
            // } else {
            //   window.location.href = "/";
            // }
          })
          .catch(() => {
            // on cancel
          });
    },
    onClickLeft() {
      if (this.$route.name === "home") {
        window.location.href = "https://wzcjmp.aigupiao8.com";
      } else if (this.$route.name === "login" && !this.$ls.get("user_id")) {
        this.$router.replace("/");
      } else {
        this.$router.back();
      }
      // this.$router.push({ name: "home" });
      // if (this.$route.name === "home") {
      //   window.location.href = "https://wzcjmp.aigupiao8.com";
      // } else {
      //   this.$router.push({
      //     name: "home",
      //     query: {
      //       teacher_id: this.teacher_id,
      //     },
      //   });
      // }
    },
  },
  watch: {
    $route: () => {
      if(!that.$ls.get("user_id")) {
        that.phone = "";
      }
      that.getRouteName();
    },
  },
};
</script>

<style>
html {
  font-size: 16px !important;
}

@media screen and (min-width: 768px) {
  .van-tabbar {
    width: 50% !important;
    margin: auto;
    transform: translateX(-50%);
    left: 50% !important;
  }
}

.van-tag {
  padding: 0.2rem !important;
  font-size: 0.88rem !important;
}

.van-cell__value.notes {
  text-align: left !important;
}
</style>
